import { format } from 'date-fns'
import { parseISO } from 'date-fns/esm'

export const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1)

export const getDateTimeArray = (string) => {
  const dateTime = new Date(string)
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  const date = `${months[dateTime.getMonth()]} ${dateTime.getDate()}, ${dateTime.getFullYear()}`
  const time = dateTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).toLowerCase()
  return [date, time]
}

export const titleize = (str) => {
  if (typeof str !== 'string') {
		throw new TypeError('Expected a string');
	}

	return str.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
}

export const capitalizeUnderscore = (string) => {
    const removeUndercore = string.split('_').join(' ')
    return titleize(removeUndercore)
}

export const formatDateTime = (dateStr) => {
  try {
    const dateInstance = new Date(parseISO(dateStr))
    const formattedDate = format(dateInstance, "MMM d, yyyy")
    const formattedTime = format(dateInstance, "hh:mm a")
    return [formattedDate, formattedTime]
  } catch {
    console.log("invalid date string")
    return ["", ""]
  }
}

export const getValidTrackingNos = (trackingNumbers) => {
    // trackingNumbers are array
    // check user input validation
    let validArray = [];
    trackingNumbers.forEach(t => {
      if (t.trim() !== "" && t.trim() !== "%20");
      validArray.push({ tracking_no: t.trim() });
    });
    return validArray
}

export const groupObjectsByProp = (objArray, propName) => {
  const result = {}
  if (!objArray) return result
  for (let a of objArray) {
    if (!!result[a[propName]]) {
      result[a[propName]].push(a)
    } else {
      result[a[propName]] = [a]
    }
  }
  return result
}

export const transformAPIV2Data = objectArray => {
  const result = {}
  for (let a of objectArray) {
    const updatedObj = {...a, updates: a.status_updates}
    delete updatedObj.status_updates
    result[a.tracking_no] = updatedObj
  }
  return result
}