import axios from 'axios'

export let BASE_URL
export let JANIO_BACKEND_API

const url = window.location.href;
if (process.env.REACT_APP_IS_LOCAL) {
  BASE_URL = 'http://tracker.janio.local/api';
  JANIO_BACKEND_API = 'http://merchant.janio.local/api'
} else if (process.env.REACT_APP_IS_STAGING) {
  BASE_URL = 'https://tracker.staging.janio.asia/api';
  JANIO_BACKEND_API = 'https://merchant.staging.janio.asia/api'
} else if (process.env.REACT_APP_IS_PROD) {
  BASE_URL = 'https://tracker.janio.asia/api';
  JANIO_BACKEND_API = 'https://merchant.janio.asia/api'
} else if (process.env.REACT_APP_IS_PROD_CN) {
  BASE_URL = 'https://tracker.janio.cn/api';
  JANIO_BACKEND_API = 'https://merchant.janio.cn/api'
} else {
  if (url.includes('localhost')) {
    BASE_URL = 'https://tracker.staging.janio.asia/api';
	  JANIO_BACKEND_API = 'http://localhost:8000/api';
	} else {
    BASE_URL = 'https://janio-api-tracker.herokuapp.com/api'
    JANIO_BACKEND_API = 'https://janio-api.herokuapp.com/api'
  }
}

// regular http call
export const http = () => axios.create({
  baseURL: BASE_URL,
  headers: {'Content-Type': 'application/json'}
})
