import React from "react";
import { Menu, Dropdown, Icon, Affix } from "antd";

// const MENUITEM = ['English', 'Bahasa Indonesia', '中文']
const MENUITEM = ["English"];
const logo = require("../assets/img/janio-logo2.png");

const menu = props => {
  return (
    <Menu
      onClick={e => {
        props.changeLanguage(e.key);
      }}
    >
      {MENUITEM.map((value, key) => (
        <Menu.Item key={value}>
          <a href="#!"
            rel="noopener noreferrer"
            className={props.current === value ? "active" : ""}
          >
            <span className="icon-content">
              {props.current === value && <Icon type="check" />}
            </span>{" "}
            {value}
          </a>
        </Menu.Item>
      ))}
    </Menu>
  );
};

export const NavbarWithLogo = props => (
  <div>
    <Affix
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        zIndex: 10,
        boxShadow: "0 2px 4px 0 rgba(183,190,197,0.51)",
        background: "white"
      }}
    >
      <div className="new-navbar-wrap">
        <a href="/">
          <img className="logo" src={logo} alt="logo" />
        </a>
        <Dropdown
          overlay={menu(props)}
          trigger={["click"]}
          placement="bottomRight"
        >
          <a className="ant-dropdown-link lang-dropdown" href="#!">
            {props.current}
            <Icon type="caret-down" />
          </a>
        </Dropdown>
      </div>
    </Affix>
  </div>
);

// OLD CODE
// import styled from 'styled-components'
// import {Navbar as NavbarBs, Nav as NavBs} from 'react-bootstrap'
// import {colors} from './themes'

// export const NavbarBase = styled(NavbarBs)`
//   background-color: white;
//   height: 60px;
//   box-shadow: ${props => props.shadow ? '0 2px 4px 0 rgba(183, 190, 197, 0.51)' : 'none'};
// `

// export const NavbarFlex = styled(NavbarBase)`
//   display: flex;
//   justify-content: center;
//   img {
//     height: 48px;
//   }
// `

// export const NavbarWithLogo = () => (
//   <NavbarFlex shadow="true" expand="lg">
//       <img src={logo} alt="Janio" />
//   </NavbarFlex>
// )

// export const Navbar = styled(NavbarBase)`
//   padding: 0 30px;
// `

// export const NavbarCollapse = styled(NavbarBs.Collapse)`
//   height: 100%;
// `

// export const Nav = styled(NavBs)`
//   height: 100%;
// `

// export const MenuNavComponent = (props) => (
//   <div className={props.className}>
//     <span className="menu-title">{props.title}</span>
//     {props.actions ?
//       <div className="menu-actions">
//         {props.actions}
//       </div>
//       : ''
//     }
//   </div>
// )

// export const MenuNav = styled(MenuNavComponent)`
//   height: 60px;
//   padding: 0 30px;
//   background-color: ${colors.greyDarker};
//   border-bottom: 1px solid ${colors.greyDarkest};
//   display: flex;
//   justify-content: space-between;
//   align-items: center;

//   .menu-title {
//     font-size: 18px !important;
//   }
//   .menu-actions > * {
//     margin-left: 8px;
//   }
// `
