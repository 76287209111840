import React, {useState, useEffect} from "react";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { AntCard, AntButton } from "../components";
import {UnsubscribeAPI} from "../api/unsubscribeApi";


const unsubscribeSuccess = require("../assets/img/unsubscribe_success.svg");
const alreadyUnsubscribed = require("../assets/img/already-unsubscribed.svg");
const orderNotFound = require("../assets/img/order-not-found.svg");
const unsubscribeSuccessMessage = "Unsubscribe Successfully";
const alreadyUnsubscribedMessage = "Already Unsubscribed";
const orderNotFoundMessage = "Order Not Found";


const Unsubscribe = ({location, history}) => {
  const [trackingNumber, setTrackingNumber] = useState();
  const [showImage, setShowImage] = useState();
  const [showMessage, setShowMessage] = useState();

  useEffect(()=>{
    const params = queryString.parse(location.search);
    setTrackingNumber(params.tracking_number)
    UnsubscribeAPI.unsubscribeEmail(params.tracking_number, params.email)
    .then((res)=>{
      setShowImage(unsubscribeSuccess);
      setShowMessage(unsubscribeSuccessMessage);
    }).catch((err)=>{
      if (
        err
        && err.response
        && err.response.status === 400
        && err.response.data
        && err.response.data
        && err.response.data.tracking_no
        && err.response.data.tracking_no[0] === "Order does not exist"
        ){
        setShowImage(orderNotFound);
        setShowMessage(orderNotFoundMessage);
      }else{
        setShowImage(alreadyUnsubscribed);
        setShowMessage(alreadyUnsubscribedMessage);

      }
    });
    
  }, [location.search]);
  return (
    <AntCard border="true" style={{ marginBottom: 24 }}>
      <div style={{ width: "70%", margin:"0 15%", textAlign: "center" }}>
        <img src={showImage} width="35%" alt="Unsubscribe Icon"/>
        <p className="unsubscribe-title">{showMessage}</p>
        <p className="unsubscribe-tracking-number">{trackingNumber}</p>
        <p className="unsubscribe-desc"> We won&#39;t be sending you anymore updates on your parcel status.</p>
        <AntButton 
          type="primary"
          shape="round" size="large"
          variant='white'
          greenborder='true'
          width='30%'
          fontSize='12px'
          onClick={()=> history.push('/')}>
          OK
        </AntButton>
      </div>
    </AntCard>
  )
}

export default withRouter(Unsubscribe)
