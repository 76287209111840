import React from "react"
import { Icon, Row, Col } from "antd";
import { capitalizeUnderscore, formatDateTime } from "../utils";
const blinkingIcon = require("../assets/img/tracker timeline.png");


const TrackingTable = ({ data, detail, count }) => (
  <div className="tracker-timeline-wrapper">
    <ul className="tracker-timeline">
      {data.map((update, index) => {
        const [date, time] = formatDateTime(update.updated_on);
        let classes = "";
        if (index !== 0 || update.status === "SUCCESS") {
          classes = "complete";
        }
        const location = [...new Set([update.address, update.destination_country])].filter(Boolean).join(", ");

        const getDisplayProp = () => {
          if ((count === 1) && (!detail && index > 1)) {
            return 'none'
          } else if (!detail) {
              return 'none'
          }
          return ''
        }

        return (
          <li className={`tracker__item active ${classes}`} key={update.update_id}
            style={{
              display: getDisplayProp()
            }}>
            <Row className="tracker__wrapper">
              <Col xs={10} className="tracker__time">
                <p className="tracker__time--date">{date}</p>
                <p className="tracker__time--time">{time}</p>
                <span className={`tracker__check ${update.status === "SUCCESS" ? "success" : ""}`}>
                  {(update.status !== 'SUCCESS' && data[0].status === update.status && index === 0) ?
                    <span style={{ paddingBottom: 6, background: 'white', display: 'block' }}>
                      <div className="tracker__onprogress shadow-animation">
                        <img src={blinkingIcon} width="15" height="15" alt="Blinking Icon" />
                      </div>
                    </span> :
                    <Icon type="check-circle" theme="filled" className=" tracker_icon" />
                  }
                </span>
              </Col>

              <Col xs={14} className="tracker__shipment-status">
                <div className="tracker__description">
                  <div>
                    <p className="tracker__status">
                      {
                        update.status === 'ORDER_INFO_RECEIVED'
                          ? 'Order Info Received'
                          : capitalizeUnderscore(update.status)
                      }
                    </p>
                  </div>
                  {update.main_text &&
                  <p className="tracker__text">{update.main_text}</p>
                  }
                  {update.detail_text &&
                  <p className="tracker__text">{update.detail_text}</p>
                  }
                </div>
                {(update.address || update.destination_country) &&
                  <div className="tracker__location">
                    <span>{location}</span>
                  </div>
                }
              </Col>
            </Row>
          </li>
        );
      })}
    </ul>
  </div>
);

export default TrackingTable