import TweenOne from "rc-tween-one";
import React from "react";

import queryString from "query-string";
import { SubscribeAPI } from "../api/subscribeApi";
import { TrackingAPI } from "../api/trackingApi";
import { AntButton, AntCard, AntInput } from "../components";
import { LoadingScreen } from "../components/Loading";
import Tracking from "./Tracking";

import { Col, Form, Row } from "antd";
import { ERROR, GET_POD, MESSAGE, SUCCESS, TRACKING_NOS, TRACK_SHIPMENTS } from "../constants/mixpanel";
import { transformAPIV2Data } from "../utils";
import { mixPanelTrack } from "../utils/mixpanelUtils";

const logo = require("../assets/img/janio_big_logo.svg");
const home_back = require("../assets/img/home-back.png");

class Public extends React.Component {
  state = {
    loading: false,
    data: null,
    loadingStarted: false,
    trackingNums: "",
    isOldApi: this.isOldApi(),
    successTrackingNumber: null,
    error: { trackingNumber: null, message: null },
  };

  isOldApi() {
    let parsedQueryString = queryString.parse(window.location.search);
    parsedQueryString = { ...parsedQueryString }; // convert to js object
    return !!Object.entries(parsedQueryString).length && parsedQueryString.hasOwnProperty("old");
  }

  componentDidMount() {
    this.props.path(false);
    const trackingNumbers = window.location.pathname.split("/")[1];
    if (trackingNumbers.length) {
      this.loadTrackings(trackingNumbers);
    }
  }

  async loadTrackings(nos) {
    const { location } = this.props;
    await this.setTrackingNos(nos);

    mixPanelTrack(TRACK_SHIPMENTS, {
      [TRACKING_NOS]: nos,
    });

    // window.history.replaceState(null, "", this.state.trackingNums);
    window.history.replaceState(null, "", this.state.trackingNums + location.search);

    let data;
    if (this.state.isOldApi) {
      data = await TrackingAPI.getTrackingData(this.state.trackingNums);
    } else {
      const responseData = await TrackingAPI.getTrackingDataV2(this.state.trackingNums);
      data = transformAPIV2Data(responseData);
    }

    this.setState({ loading: false, data });
    this.props.path(true);
  }

  setTrackingNos(val) {
    let trackingNos = val.toString().split(/,| /);
    trackingNos = trackingNos.filter((el) => {
      return el !== "" && el != null;
    });
    trackingNos = trackingNos.filter((v, i, a) => a.indexOf(v) === i);

    this.setState({
      trackingNums: trackingNos,
      loadingStarted: true,
      loading: true,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let nos = values.nos;
        this.loadTrackings(nos);
      }
    });
  }

  //prevent special characters
  specialCharacters = (e) => {
    e.preventDefault();
    e.target.value = e.target.value.replace(/[!@#$%^&*(),.?":{}|<>]/g, "");
  };

  noDataTracking = (trackingNo) => {
    return (
      <AntCard border="true" style={{ marginBottom: 24 }} key={trackingNo}>
        <div className="pt-20">
          <p className="tracking-title">Tracking Number</p>
          <h1 className="tracking-number">{trackingNo}</h1>
        </div>
        <hr className="" />
        <div>
          <h2 className="text-center mb-0" style={{ fontSize: 22 }}>
            No data
          </h2>
        </div>
      </AntCard>
    );
  };

  handleSendPod = async (trackingNumber) => {
    const result = await SubscribeAPI.sendEmail(trackingNumber);
    if (result.message) {
      this.setState({ successTrackingNumber: trackingNumber });
      mixPanelTrack(GET_POD, {
        [SUCCESS]: true,
        [MESSAGE]: result.message,
      });
    } else if (result.error) {
      this.setState({ errorTrackingNumber: trackingNumber, message: result.error });
      mixPanelTrack(GET_POD, {
        [SUCCESS]: false,
        [ERROR]: result.error,
      });
    }
  };

  render() {
    let trackings;
    if (this.state.loading) {
      trackings = (
        <div style={{ position: "relative", marginTop: "4rem" }}>
          <LoadingScreen />
        </div>
      );
    } else if (this.state.data) {
      trackings = [];
      let iterator = [...Object.keys(this.state.data), ...this.state.trackingNums];
      iterator = [...new Set(iterator)];
      for (let trackingNo of iterator) {
        if (this.state.data[trackingNo]) {
          const { updates, ...trackingInfo } = this.state.data[trackingNo];
          if (updates.length) {
            trackings.push(
              <Tracking
                showEDD={true}
                key={trackingNo}
                trackingNo={trackingNo}
                data={updates}
                trackingInfo={trackingInfo}
                isOldApi={!!this.state.isOldApi}
                count={Object.keys(this.state.data).length}
                handleSendPod={this.handleSendPod}
                successTrackingNumber={this.state.successTrackingNumber}
                errorTrackingNumber={this.state.error}
              />
            );
          } else {
            trackings.push(this.noDataTracking(trackingNo));
          }
        } else {
          trackings.push(this.noDataTracking(trackingNo));
        }
      }
    }

    const { getFieldDecorator } = this.props.form;

    return (
      <main style={{ minHeight: 400 }}>
        <div style={{ position: "relative" }}>
          <div>
            <Form onSubmit={this.handleSubmit.bind(this)} className="tracking-form">
              {!this.state.data && (
                <div key="4" className="home-logo">
                  <img
                    src={logo}
                    style={{
                      maxWidth: "360px",
                      maxHeight: "175px",
                      width: "100%",
                      marginBottom: "45px",
                    }}
                    className="xs-logo img-responsive"
                    alt="Logo"
                  />
                  <p className="tag" />
                </div>
              )}

              <TweenOne
                animation={{
                  marginTop: -350,
                  duration: 200,
                }}
                paused={!this.state.data}
              >
                {this.state.data && <div className="hiddenBox" style={{ height: 360 }} />}
              </TweenOne>

              <Row className="input-row" style={{ display: "flex", alignItems: "center" }}>
                <Col sm={16} className="gutter-row">
                  <Form.Item label="">
                    {getFieldDecorator("nos", {
                      initialValue: this.state.trackingNums.toString(""),
                      rules: [
                        {
                          required: true,
                          message: "Please Enter Tracking ID!",
                        },
                        {
                          max: 255,
                          message: "Ensure this field has no more than 255 characters",
                        },
                      ],
                    })(<AntInput className="" key="3" round="true" shadow="true" indent="true" max={255} autosize="true" placeholder="Enter Parcel Tracking ID" />)}
                  </Form.Item>
                </Col>
                <Col sm={8} className="gutter-row" style={{ textAlign: "right" }}>
                  {
                    <AntButton
                      className=""
                      key="1"
                      type="primary"
                      htmlType="submit"
                      shape="round"
                      size="large"
                      bold="true"
                      variant="white"
                      padding="0 15px"
                      greenborder="true"
                      disabled={this.state.loading}
                      width="90%"
                      fontSize="15px"
                      margin="0 0 29px 0"
                    >
                      Track Shipments
                    </AntButton>
                  }
                </Col>
              </Row>
            </Form>
          </div>

          <TweenOne
            animation={{
              height: 0,
              duration: 200,
              delay: 1000,
            }}
            paused={!this.state.data}
          >
            {this.state.data && <div style={{ height: 550, position: "relative", zIndex: -1 }} />}
          </TweenOne>
          {trackings}
        </div>
        {!this.state.data && (
          <div>
            <img className="home-back" src={home_back} alt="logo" />
          </div>
        )}
      </main>
    );
  }
}

const PublicTrackingUI = Form.create({ name: "coordinated" })(Public);

export default PublicTrackingUI;
