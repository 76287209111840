import axios from "axios";
import { BASE_URL, JANIO_BACKEND_API } from ".";

export const SubscribeAPI = {
  subscribeEmail: async (trackingNumber, email) => {
    const payload = {
      email: email,
      tracking_no: trackingNumber,
    };
    const url = `${JANIO_BACKEND_API}/subscription/tracker-update-email-subscription/`;
    try {
      const response = await axios.post(url, payload, {
        headers: { "Content-Type": "application/json" },
      });
      const data = await response.data;
      return data;
    } catch (e) {
      const data = {};
      data.isError = true;
      return data;
    }
  },

  unsubscribeEmail: async (trackingNumber, email) => {
    const payload = {
      email: email,
      tracking_no: trackingNumber,
    };
    const url = `${JANIO_BACKEND_API}/subscription/tracker-update-email-unsubscription/`;
    const response = await axios.post(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    const data = response.data;
    return data;
  },

  sendEmail: async (trackingNumber) => {
    const payload = {
      tracking_nos: trackingNumber,
    };
    try {
      const url = `${BASE_URL}/tracker/send-epod-to-consignee/`;
      const response = await axios.post(url, payload, {
        headers: { "Content-Type": "application/json" },
      });
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  },

  subscribeUpdates: async (tracking_no) => {
    const url = `${JANIO_BACKEND_API}/subscription/tracker-update-consignee-subscription/`;
    try {
      const response = await axios.post(
        url,
        { tracking_no },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const data = await response.data;
      return data;
    } catch (e) {
      const data = {};
      data.isError = true;
      return data;
    }
  },
};
