import React, { Component } from 'react'
import ReactGA from "react-ga";
import { Route, Switch, Link, BrowserRouter as Router } from 'react-router-dom'
import { history } from './utils/historyUtils'
import { Layout } from 'antd';

import PublicTrackingUI from './tracking/PublicTrackingUI'
import Faqs from './tracking/Faqs'
import {NavbarWithLogo} from './components/Navbar'
import Unsubscribe from './tracking/Unsubscribe'


const { Header, Footer, Content } = Layout;


ReactGA.initialize("UA-153521745-1");
history.listen((location, action) => {
  console.log('route changes...')
  ReactGA.pageview(window.location.pathname);
});


class App extends Component {
  state = {
    currentLanguage: 'English',
    loaded: false
  }

  changeLanguage(value) {
    this.setState({currentLanguage:value})
  }

  setPath(loaded) {
    this.setState({
      loaded: loaded
    })
  }

  render() {
    const path = window.location.pathname.split('/')[1]

      return(
        <Router history={history}>
          <Layout style={styles.layout} className="ant-layout">
            <Header style={styles.header}>
              <NavbarWithLogo current={this.state.currentLanguage}
                    changeLanguage={(e) => this.changeLanguage(e)} pgName={path} loaded={this.state.loaded}/>
            </Header>
            <Content style={styles.content} className="ant-content">

                <Switch>
                  <Route path="/faq" component={Faqs} />
                  <Route path="/unsubscribe" render={(props) => <Unsubscribe {...props}/>}/>
                  <Route path="/" render={(props) => <PublicTrackingUI {...props} path={(path) => this.setPath(path)} />} />
                </Switch>
                
            </Content>
            
            <Switch>
              <Route path="/(faq|unsubscribe)" render={(props) =>
                <Footer style={styles.footer} className="ant-footer">
                  <p className="hint">Did Not Find Your Answer?</p>
                  <p className="links">Feel free to contact us at&nbsp;
                  <a className='contact-link' href="mailto: help@janio.asia">help@janio.asia</a></p>
                </Footer>
              }/>
            <Route path="/" render={(props) => {
              return(
                this.state.loaded && path !== "" && 
                  <Footer style={styles.footer} className="ant-footer">
                    <Link className="links" to="/faq">Need Help? Click Here!</Link>
                  </Footer>
                )
            }}/>
          </Switch>
          </Layout>
        </Router>
      )
  }
}

const styles = {
  layout: {
    background: 'transparent',
    minHeight: '100vh'
  },

  header : {
    lineHeight: 'normal',
    height: 60
  },

  content: {
    maxWidth: 588,
    margin: '20px auto 0px auto',
    width: '100%',
    textAlign: 'center',
    padding: '0 20px'
  },

  footer: {
    background: '#050593',
    width: '100%',
    display: 'flex',
    padding: '27px 27px',
    flexDirection: 'column',
    justifyContent: 'center',
  }
}

export default App
