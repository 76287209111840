import axios from 'axios'
import { JANIO_BACKEND_API } from '.'

export const UnsubscribeAPI = {
  unsubscribeEmail: (trackingNumber, email) => {
    const payload = {
      email: email,
      tracking_no: trackingNumber
    }
    const url = `${JANIO_BACKEND_API}/subscription/tracker-update-email-unsubscription/`
    return axios.post(url, payload)
  }
}