export function mixPanelTrack(eventName, payload) {
  window.mixpanel.track(eventName, payload);
}

export function saveMixpanelData(data) {
  localStorage.setItem("mixpanelData", JSON.stringify(data));
}

export function getMixpanelData() {
  return JSON.parse(localStorage.getItem("mixpanelData"));
}
