import React from 'react'

export const covidMessagesCountry = {
  default: () => (
    <p>
      Due to the outbreak of COVID-19, we are experiencing restricted movement across
      the world. Please expect a delay in processing and delivery of shipments during this period.
      Janio is monitoring the situation closely and will do its utmost to make sure that 
      your package is successfully delivered.
    </p>
  ),

  Singapore: () => (  
    <div>
      <p>
      Due to the outbreak of COVID-19, we are experiencing restricted movement across the world.
      Please expect a delay in shipments going into Singapore due to longer queues clearing customs.
      Janio is monitoring the situation closely and will do its utmost to make sure that your package is successfully delivered.
      Please <a href="https://janio.asia/articles/covid-19-updates/" target="_blank" rel="noopener noreferrer">click here</a> to get more information.
      </p>
    </div>
  ),

  Malaysia: () => (
    <div>
      <p>
      Due to the outbreak of COVID-19, we are experiencing restricted movement across the world. 
      Please expect a delay in shipments going into Malaysia due to the implemented Restricted Movement Order (RMO). 
      Janio is monitoring the situation closely and will do its utmost to make sure that your package is successfully delivered. 
      Please <a href="https://janio.asia/articles/covid-19-updates/" target="_blank" rel="noopener noreferrer">click here</a> to get more information.
      </p>
    </div>
  ),

  Indonesia: () => (
    <div>
      <p>
      Due to the outbreak of COVID-19, we are experiencing restricted movement across the world. 
      Please expect a delay in shipments going into Indonesia due to the implemented large-scale social restrictions (PSBB) and limitations on commercial flights. 
      Janio is monitoring the situation closely and will do its utmost to make sure that your package is successfully delivered. 
      Please <a href="https://janio.asia/articles/covid-19-updates/" target="_blank" rel="noopener noreferrer">click here</a> to get more information.
      </p>
    </div>
  ),

  Philippines: () => (
    <p>
      Due to the outbreak of COVID-19, we are experiencing restricted movement across the world.
      Please expect a delay in shipments going into the Philippines due to the implemented Extended Community Quarantine (ECQ) and General Community Quarantine (GCQ). 
      Janio is monitoring the situation closely and will do its utmost to make sure that your package is successfully delivered. Please <a href="https://janio.asia/articles/covid-19-updates/" target="_blank" rel="noopener noreferrer">click here</a> to get more information.
    </p>
  )
}

export const getMessage = (country='default') => {
  let selectedCountry = country
  if (!covidMessagesCountry.hasOwnProperty(country)) {
    selectedCountry = 'default'
  }
  const Component = covidMessagesCountry[selectedCountry]
  return <Component />
}