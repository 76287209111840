import React, { useState } from "react";
import { SubscribeAPI } from "../api/subscribeApi";
import { Form, Alert } from "antd";
import { capitalizeUnderscore } from "../utils";
import { AntInput, AntButton } from "../components";
import { mixPanelTrack } from "../utils/mixpanelUtils";
import { EMAIL, SUBSCRIBE_EMAIL, SUCCESS, TRACKING_NOS } from "../constants/mixpanel";

const SubscriptionForm = (props) => {
  const [state, setState] = useState({
    subscribeText: "",
    subscribeValid: null,
    subscriptionSuccess: null,
  });
  const { getFieldDecorator } = props.form;

  const handleInput = (e) => {
    const { value } = e.target;
    setState({
      ...state,
      subscribeText: value,
      subscriptionSuccess: null,
      submitting: false,
    });
    console.log("tes");
  };

  const subscribe = async (email) => {
    const data = await SubscribeAPI.subscribeUpdates(props.trackingNo);
    if (data.isError) {
      setState({
        ...state,
        subscriptionSuccess: false,
        submitting: false,
      });
      mixPanelTrack(SUBSCRIBE_EMAIL, {
        [SUCCESS]: false,
        [EMAIL]: email,
        [TRACKING_NOS]: props.trackingNo,
      });
    } else {
      setState({
        ...state,
        subscriptionSuccess: true,
        submitting: false,
      });
      mixPanelTrack(SUBSCRIBE_EMAIL, {
        [SUCCESS]: true,
        [EMAIL]: email,
        [TRACKING_NOS]: props.trackingNo,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      // var re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // var valid = re.test(values.email.toLowerCase());

      if (props.email) {
        setState({
          ...state,
          subscribeValid: "true",
          submitting: true,
        });
        subscribe();
      } else {
        setState({
          ...state,
          subscribeValid: "false",
        });
      }
    });
  };

  return (
    <div>
      <hr className="m-20" />
      <div className="subscribe mb-0">
        <p className="tracking-title mt-0" style={{ paddingTop: 0 }}>
          {capitalizeUnderscore("Subscribe To Delivery Updates")}
        </p>
        <Form onSubmit={handleSubmit} className="subscribe-row">
          <Form.Item style={{ marginBottom: 0 }}>
            {/* <p className="email-label">Email</p> */}
            {/* <p className="sub-title">Receive delivery updates by email</p> */}
            {/* {getFieldDecorator("email", {
              rules: [],
            })(<AntInput onChange={handleInput} className={state.subscribeValid === "true" ? "success" : state.subscribeValid === "false" ? "error" : ""} />)} */}
            {state.subscribeValid === "false" && (
              <Alert
                className="mt-10"
                message="We can not find any email associated with this tracking number, please contact our customer support for further information"
                type="error"
              />
            )}
            {state.subscriptionSuccess === true && <Alert className="mt-10" message="Subscribed successfully" type="success" />}
            {state.subscriptionSuccess === false && (
              <Alert
                className="mt-10"
                message="We can not find any email associated with this tracking number, please contact our customer support for further information"
                type="error"
              />
            )}
          </Form.Item>
          <Form.Item>
            <AntButton
              type="primary"
              className="f-23"
              htmlType="submit"
              size="large"
              variant="green"
              margin="23px 0 0 0"
              width="100%"
              disabled={state.submitting}
              disabledcolor="disabled"
            >
              Get Delivery Updates
            </AntButton>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Form.create({ name: "email_subscription" })(SubscriptionForm);
