import React from "react";
import moment from "moment";
import { AntCard } from "../components";
import { Icon, Alert } from "antd";
import SubscriptionForm from "./SubscriptionForm";
import { getMessage } from "./covidMessages";
import TrackingTable from "../components/TrackingTable";
import TrackingTableOld from "../components/TrackingTable.old";
import TopStatus from "../components/TopStatus";

const getEdd = ({ est_date, early_est_date, data, consignee_country }) => {
  // if falsy
  if (!est_date) return false;
  if (!early_est_date) return false;
  if (consignee_country !== "Singapore" && consignee_country !== "Malaysia") return false;

  const early_edd = moment(early_est_date);
  const edd = moment(est_date);

  // if status success
  if (data[0].status.toUpperCase() === "SUCCESS") return false;
  // if in the past
  if (edd.isBefore(moment())) return false;

  return [early_edd, edd];
};

const EddDisplay = ({ earlyEdd, edd }) => {
  const early = earlyEdd.format("DD MMM YYYY").toUpperCase();
  const late = edd.format("DD MMM YYYY").toUpperCase();
  if (early === late) {
    return (
      <>
        <p className="tracking-title">Estimated Delivery Date</p>
        <h1 className="tracking-number">{early}</h1>
      </>
    );
  }

  return (
    <>
      <p className="tracking-title">Estimated Delivery Date</p>
      <h1 className="tracking-number">
        {early} - {late}
      </h1>
    </>
  );
};

class Tracking extends React.Component {
  state = {
    showDetails: false,
    showErrorAlert: false,
    showSuccessAlert: false,
  };

  handleShowDetails() {
    this.setState({ showDetails: !this.state.showDetails });
  }

  showNoEmailError = () => {
    if (!this.props.trackingInfo.masked_consignee_email) {
      this.setState({ showErrorAlert: true });
    }
  };

  render() {
    // const { data, trackingNo, count } = this.props;
    const { data, trackingNo, count, trackingInfo, showEDD, isOldApi } = this.props;
    const eddData = showEDD ? getEdd({ ...trackingInfo, data }) : false;
    let earlyEdd = false;
    let edd = false;

    if (eddData) {
      [earlyEdd, edd] = eddData;
    }

    const topStatus = data[0];

    return (
      <AntCard border="true" style={{ marginBottom: 24 }}>
        <p className="tracking-title">Tracking Number</p>
        <h1 className="tracking-number">{trackingNo}</h1>
        {(this.state.showErrorAlert || this.props.errorTrackingNumber.trackingNumber === trackingNo) && (
          <Alert
            showIcon
            message={
              this.state.showErrorAlert
                ? "We can not find any email associated with this tracking number, please contact our customer support for further information"
                : this.props.errorTrackingNumber.message
            }
            type="error"
            className="mb-3 capitalize-first-letter"
          />
        )}
        {(this.state.showSuccessAlert || this.props.successTrackingNumber === trackingNo) && (
          <Alert
            showIcon
            message="Proof of Delivery report has been sent to the email address you provided with this order, please check the email to view and download the report."
            type="success"
            className="mb-3"
          />
        )}

        {eddData && <EddDisplay earlyEdd={earlyEdd} edd={edd} />}

        <TopStatus update={topStatus} isNewApi={!this.props.isOldApi} />

        {/* tracking updates details */}
        {isOldApi ? (
          <TrackingTableOld data={data} detail={this.state.showDetails} count={count} />
        ) : (
          this.state.showDetails && (
            <TrackingTable
              data={data}
              trackingNo={trackingNo}
              showDetails={this.state.showDetails}
              count={count}
              email={trackingInfo.masked_consignee_email}
              handleSendPod={this.props.handleSendPod}
              showNoEmailError={this.showNoEmailError}
              hasEpod={trackingInfo.epod_exists}
            />
          )
        )}

        <a
          href="#/"
          className="text-normal text-medium text-center arrow"
          onClick={(e) => {
            e.preventDefault();
            this.handleShowDetails();
          }}
        >
          {this.state.showDetails ? (
            <div>
              <Icon type="up" />
            </div>
          ) : (
            <div>
              <Icon type="down" />
            </div>
          )}
          <p className="mb-0">{this.state.showDetails ? "Show less details" : "Show more details"}</p>
        </a>
        {/* Email subscribe */}
        {<SubscriptionForm trackingNo={trackingNo} email={trackingInfo.masked_consignee_email} />}

        <hr />
        {/* Alert message */}
        <div>
          <div style={{ padding: "5px 0" }}></div>
          <Alert className="ant-alert-bold" message="Urgent Update" type="warning" showIcon description={getMessage(trackingInfo.consignee_country)} />
          <div style={{ padding: "10px 0" }}></div>
        </div>
      </AntCard>
    );
  }
}

export default Tracking;
