import { http } from ".";
import { getValidTrackingNos } from "../utils";

async function getData(url, params = {}) {
  try {
    const response = await http().get(url, { ...params });
    return response.data;
  } catch (err) {
    console.log(err);
    return {
      errorData: (err.response && err.response.data) || {},
      isError: true,
    };
  }
}

export const TrackingAPI = {
  getTrackingData: async (trackingNumbers) => {
    const validArray = getValidTrackingNos(trackingNumbers);
    const payload = {
      get_related_updates: true,
      tracking_nos: validArray,
    };

    const response = await http().post("/tracker/extended-query-combined-updates/", payload);
    const data = await response.data;
    return data;
  },

  async getTrackingDataV2(trackingNumbers) {
    const validTrackingNumbers = getValidTrackingNos(trackingNumbers);
    const url = "/v2/tracker/related-updates";
    const params = {
      tracking_nos: validTrackingNumbers.map((t) => t.tracking_no).join(","),
    };
    const responseData = await getData(url, { params });
    console.log("responseData", responseData);
    return responseData;
    // return new Promise(resolve => setTimeout(() => resolve(newAPIMockData), 2000))
  },
};
