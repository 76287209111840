import React from 'react'
import { capitalizeUnderscore, formatDateTime } from '../utils';
import { Row, Col } from "antd";

const TopStatus = ({ update, isNewApi=false }) => {
  const {
    status, update_type, address,
    main_text, detail_text, updated_on,
    destination_country, description
  } = update

  let transportIcon;
  try {
    const statusLowerCase = status.toLowerCase()
    let ext = 'png'
    if (statusLowerCase.includes('intra')) {
      ext = 'svg'
    }
    transportIcon = require(`../assets/img/${statusLowerCase}.${ext}`);
  } catch {
    if (update_type === 'PRIVATE') {
      transportIcon = require('../assets/img/trolley.svg')
    } else {
      transportIcon = require("../assets/img/delivery_in_progress.svg");
    }
  }

  let bgClassName
  if (['SUCCESS', 'DELIVERY_COMPLETED'].includes(status)) {
    bgClassName = 'bk-green'
  } else if (['DELIVERY_FAILED', 'DESTROYED_AT_DESTINATION_WAREHOUSE'].includes(status)) {
    bgClassName = 'bk-red'
  } else {
    bgClassName = 'bk-blue'
  }

  const [date, time] = formatDateTime(updated_on)

  return (
    <div>
      <p className='tracking-title'>Status Update</p>
      <div className={`status-alert-box ${bgClassName}`}>
        <Row style={{ marginBottom: 10 }} gutter={25}>
          <Col className="gutter-row" xs={10}>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <img src={transportIcon} alt="transport" style={{ marginBottom: 10, marginTop: 6, width: (status === 'ORDER_INFO_RECEIVED' || status === 'ORDER_RECEIVED_BY_AIRLINE') ? '70%' : '80%' }} />
            </div>
          </Col>
          <Col className="gutter-row" xs={14}>
            <p className="title">{capitalizeUnderscore(status)}</p>
            {
              isNewApi
              ? <p className="description" style={{ marginBottom: 5, fontFamily:'AvenirLTStd-Medium'}}>{description}</p>
              : <>
                <p className="description" style={{ marginBottom: 5, fontFamily:'AvenirLTStd-Medium'}}>{main_text}</p>
                <p className="description" style={{ minHeight: 52 }}>{detail_text}</p>
                </>
            }
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col span={12}>
            <p className="info hidden-xs">{date + ' at ' + time}</p>
            <p className="info hidden-md" style={{ paddingTop: 10 }}>{date.split(',')[0] + ' at ' + time}</p>
          </Col>
          <Col span={12}>
            <p className="info hidden-xs" style={{ textAlign: 'right' }}>
              {(!!address ? address + ', ' : '') + (destination_country || '')}
            </p>
            <p className="info hidden-md" style={{ textAlign: 'right', paddingTop: 10 }}>
              {(!!address ? address + ', ' : '') + (destination_country || '')}
            </p>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default TopStatus