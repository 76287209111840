export const TRACK_SHIPMENTS = "TRACK_SHIPMENTS";
export const GET_POD = "GET_POD";
export const SUBSCRIBE_EMAIL = "SUBSCRIBE_EMAIL";

// keys
export const SUCCESS = "success";
export const ERROR = "error";
export const MESSAGE = "message";
export const EMAIL = "email";
export const TRACKING_NOS = "tracking_nos";
