import React, { useState } from "react";
import { Modal, Alert } from "antd";
import { capitalizeUnderscore, formatDateTime } from "../utils";
import classnames from "classnames";

const TrackingTable = ({ data, email, handleSendPod, trackingNo, showNoEmailError, hasEpod }) => {
  const [showModal, setShowModal] = useState(false);

  return !!data && !!data.length
    ? data.map((update, index, arr) => {
        const { status, updated_on, country, address } = update;
        const formattedStatus = capitalizeUnderscore(status);
        const [date, time] = formatDateTime(updated_on);

        const previousIndex = index - 1;
        const previousUpdate = arr[previousIndex];
        const isNewSection = index === 0 || (!!previousUpdate && previousUpdate.status !== status);
        return (
          <div key={`update-${status}-${index}`} className={classnames("status-update-item", { "status-update-item-section": isNewSection })}>
            {isNewSection && (
              <p className="text-large font-weight-bold" style={{ fontSize: 16 }}>
                {formattedStatus}
              </p>
            )}
            <div className="d-flex status-update">
              <div style={{ flex: 2 }}>
                <div className="d-none d-sm-block">
                  <p className="mb-0" style={{ fontSize: 16 }}>
                    {date}, {time}
                  </p>
                </div>
                <div className="d-block d-sm-none">
                  <p className="mb-0" style={{ fontSize: 16 }}>
                    {date}
                  </p>
                  <p style={{ fontSize: 16 }}>{time}</p>
                </div>
              </div>
              <div style={{ flex: 3, marginLeft: 24 }}>
                <p style={{ fontSize: 16 }}>{update.description}</p>
                {status === "DELIVERY_COMPLETED" && hasEpod && (
                  <div className="mb-3">
                    <button
                      className="btn"
                      style={{ background: "#67C4C2", color: "white" }}
                      onClick={() => {
                        if (email) {
                          setShowModal(true);
                        } else {
                          showNoEmailError();
                        }
                      }}
                    >
                      Get Proof of Delivery
                    </button>
                    <Modal closable={false} footer={null} visible={showModal} onCancel={() => setShowModal(false)}>
                      <h4 style={{ fontWeight: 700 }}>Email Proof of Delivery</h4>
                      <hr className="mb-4" />
                      <div style={{ fontSize: "16px" }} className="mb-4">
                        The Proof of Delivery will be emailed to {email}
                      </div>
                      <div style={{ fontSize: "16px" }} className="mb-4">
                        Please click the 'Send' button to email this Proof of Delivery to the email address above
                      </div>
                      <div className="d-flex justify-content-end">
                        <button onClick={() => setShowModal(false)} className="btn btn-secondary" style={{ background: "white", color: "black" }}>
                          Cancel
                        </button>
                        <button
                          className="btn ml-2 btn-primary"
                          onClick={() => {
                            setShowModal(false);
                            handleSendPod(trackingNo);
                          }}
                        >
                          Send
                        </button>
                      </div>
                    </Modal>
                  </div>
                )}
                <div>
                  <p className="mb-0">{country}</p>
                  <p className="mb-0">{address}</p>
                </div>
              </div>
            </div>
          </div>
        );
      })
    : null;
};

export default TrackingTable;
